.mCSB_scrollTools .mCSB_draggerRail{
  width: remy(8px);
  background: rgba($dark, 0.05);
}
.mCSB_scrollTools .mCSB_dragger{
  .mCSB_dragger_bar{
    width: remy(8px);
    background: rgba($dark, 0.15);
  }
  &:hover{
    .mCSB_dragger_bar{
      background: rgba($dark, 0.15);
    }
  }
}
.mCSB_scrollTools_onDrag{
  .mCSB_dragger_bar{
    background: rgba($dark, 0.15) !important;
  }
}
.section-bg{
  background: $section-bg;
}
.section-bg2{
  background: $section-bg2;
}
.section-dark{
  background: #0e1221;
}
.overlay-bg {
  background: rgba(23, 27, 46, 0.6);
}


//intro area
.intro-wrapper{
  position: relative;
  height: 100vh;
  overflow: hidden;
}

//Intro search area
.directory_content_area{
  height: 85%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  .search_title_area{
    text-align: center;
    margin-bottom: remy(30px);
    .title{
      color: #fff;
      line-height: remy(62px);
      font-size: remy(40px);
      font-weight: 700;
    }
    .sub_title{
      font-size: remy(17px);
      color: rgba(#fff, 0.8);
      margin: 0;
    }
  }
  .search_form{
    .atbd_seach_fields_wrapper{
      display: flex;
      background: #fff;
      padding: remy(30px);
      border-radius: $border-radius;
      .single_search_field{
        margin-right: remy(6px);
        flex: 2;
        .form-control{
          box-shadow: none;
          width: 100%;
        }
        &.search_category,
        &.search_location{
          position: relative;
          &:before{
            position: absolute;
            content: '\f110';
            font-family: "LineAwesome";
            font-size: remy(12px);
            color: $light-gray;
            right: remy(20px);
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
          }
        }
      }
      .atbd_submit_btn{
        flex: 1;
      }
    }
  }
  .directory_home_category_area{
    margin-top: remy(33px);
    ul{
      display: flex;
      list-style-type: none;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 0 remy(-10px);
      padding: 0;
      li{
        margin: 0 remy(5px) remy(10px);
        a{
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: remy(15px);
          border: 1px solid rgba(#fff, 0.3);
          min-width: remy(115px);
          min-height: remy(100px);
          color: rgba(#fff, 0.8);
          transition: $transition-base;
          span{
            font-size: remy(30px);
            transition: $transition-base;
          }
          &:hover{
            color: #fff;
            span{
              color: #fff;
            }
          }
        }
      }
    }
  }
}

//section title
.section-title{
  text-align: center;
  margin-bottom: remy(47px);
  h2{
    margin: 0 0 remy(13px);
    font-size: remy(30px);
    font-weight: 700;
    line-height: remy(44px);
    span{
      @extend .color-primary;
      font-weight: 700;
    }
  }
  p{
    margin: 0 auto;
    font-size: remy(18px);
    width: remy(700px);
  }
}

//cta
.cta{
  .action-btns{
    display: flex;
    flex-wrap: wrap;
    margin-top: remy(35px);
    margin-bottom:0;
    li{
      margin-right: remy(20px);
      &:last-child{
        margin-right: 0;
      }
    }
  }
}

//rating styles
.br-theme-fontawesome-stars .br-widget {
  height: 28px;
  white-space: nowrap;
}
.br-theme-fontawesome-stars .br-widget a {
  font-family: "LineAwesome", sans-serif;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  margin-right: 2px;
}
.br-theme-fontawesome-stars .br-widget a:after {
  content: "\f318";
  color: #d2d2d2;
}
.br-theme-fontawesome-stars .br-widget a.br-active:after {
  color: #f2b01e;
}
.br-theme-fontawesome-stars .br-widget a.br-selected:after {
  color: #f2b01e;
}
.br-theme-fontawesome-stars .br-widget .br-current-rating {
  display: none;
}
.br-theme-fontawesome-stars .br-readonly a {
  cursor: default;
}
@media print {
  .br-theme-fontawesome-stars .br-widget a:after {
    content: '\f318';
    color: black;
  }
  .br-theme-fontawesome-stars .br-widget a.br-active:after,
  .br-theme-fontawesome-stars .br-widget a.br-selected:after {
    content: '\f318';
    color: black;
  }
}

/* All listing styles */
.all-listing-wrapper{
  padding: remy(100px) 0;
  .atbd_generic_header{
    background: #fff;
    margin-bottom: remy(30px);
    border: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: remy(25px) remy(30px);
  }
  .atbd_generic_header_title{
    h4{
      margin-bottom: remy(2px);
    }
    p{
      margin: 0;
    }
  }
  .atbd_listing_action_btn{
    .view-mode{
      margin-right: remy(10px);
      .action-btn{
        margin-right: remy(10px);
        padding: 0 remy(9px);
        &:last-child{
          margin-right: 0;
        }
        span{
          font-size: remy(21px);
        }
        &.active{
          @extend .bg-primary;
          @extend .border-primary;
          box-shadow: none;
          color: #fff;
        }
      }
    }
    .action-btn{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $border-color;
      color: $light-gray;
      font-size: remy(13px);
      padding: 0 remy(15px);
      box-shadow: none;
      border-radius: 2px;
      height: remy(40px);
      &:focus{
        box-shadow: none !important;
        border: 1px solid rgba($dark, 0.3);
      }
    }
    .dropdown-toggle{
      .caret{
        margin-left: remy(6px);
        &:before{
          content: '\f110';
          font-family: "LineAwesome";
          font-size: remy(10px);
          color: $light-gray;
        }
      }
    }
    .dropdown{
      margin-right: 10px;
      &:last-child{
        margin-right: 0;
      }
      .dropdown-menu{
        border-radius: 2px;
        border: 1px solid $border-color;
        box-shadow: 0 remy(5px) remy(15px) rgba($dark, 0.1);
      }
    }
  }
}

/* All location styles */
.atbd_location_grid_wrap{
  margin-bottom: remy(-20px);
  .atbd_location_grid{
    @extend .content-center;
    min-height: remy(60px);
    border: 1px solid $border-light;
    color: $text-grey1;
    background: #fff;
    margin-bottom: remy(20px);
    &:hover{
      @extend .color-primary;
    }
  }
}

/* author-profile css */
.atbd_auhor_profile_area{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #FFFFFF;
  padding: remy(40px);
  border: 1px solid $border-color;
  margin-bottom: remy(30px);
  .atbd_author_avatar{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    img{
      border-radius: 100%;
    }
    .atbd_auth_nd{
      margin-left: remy(20px);
      p{
        margin: remy(8px) 0 0 0;
      }
    }
  }
  .atbd_author_meta{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .atbd_listing_meta{
      display: flex;
      align-items: center;
      margin: 0 remy(25px) 0 0;
      padding-right: remy(25px);
      border-right: 1px solid $border-color;
      .atbd_listing_rating{
        @extend .bg-success;
        line-height: remy(40px);
        color: #fff;
        border-radius: 3px;
        margin-right: remy(10px);
      }
    }
    .meta-info{
      margin: 0;
      font-size: remy(13px);
      span{
        display: block;
        line-height: remy(16px);
        font-size: remy(16px);
        color: $dark;
        font-weight: 700;
      }
    }
  }
}
.atbd_author_listings_area{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: remy(20px);
}

/* Dashboard Styles */
.dashboard-nav{
  background: #fff;
  box-shadow: 0 remy(5px) remy(10px) rgba($light-gray, 0.15);
  .dashboard-nav-area{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .nav{
      .nav-link{
        padding: remy(30px) remy(20px);
        position: relative;
        color: $text-grey2;
        font-weight: 700;
        &.active{
          @extend .color-primary;
          &:before{
            @extend .bg-primary;
            position: absolute;
            content: '';
            height: 1px;
            width: 100%;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
    .nav_button{
      padding: remy(18px) 0 remy(19px);
    }
  }
}
.user_pro_img_area{
  text-align: center;
  background: #fff;
  padding: remy(40px) remy(30px);
  border: 1px solid $border-color;
  border-radius: 3px;
  & > img{
    border-radius: 100%;
  }
  .image-info{
    margin: remy(15px) 0 remy(30px);
    h6{
      margin-bottom: remy(8px);
    }
  }
  .custom-file-upload{
    margin-bottom: remy(10px);
    input{
      display: none;
    }
    label{
      margin: 0;
    }
  }
}

.user_info_wrap{
  .form-group{
    margin-bottom: remy(25px);
    &>label{
      font-weight: 700;
      color: $dark;
    }
    p{
      margin: remy(4px) 0 0;
      font-size: remy(14px);
      color: $light-gray;
    }
  }
}

.atbd_saved_items_wrapper{
  .atbdb_content_module_contents{
    padding: 0;
    .table{
      tr{
        th{
          border: 0;
          padding: remy(15px) remy(30px);
        }
        td{
          padding: remy(15px) remy(30px);
          vertical-align: middle;
          a{
            color: $dark;
            &:hover{
              @extend .color-primary;
            }
          }
          &>span{
            font-size: remy(20px);
            @extend .color-primary;
            margin-right: 5px;
          }
          .remove-favorite{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: rgba($primary, 0.05);
            @extend .color-primary;
            font-size: remy(13px);
            width: remy(25px);
            height: remy(25px);
            border-radius: remy(300px);
            &:hover{
              @extend .bg-primary;
              color: #fff;
            }
          }
          &:last-child{
            text-align: right;
          }
        }
      }
    }
  }
}

/* Add Listing Styles */
.add-listing-wrapper{
  .atbd_content_module{
    margin-bottom: remy(30px);
  }
}
.atbd_content_module{
  .form-label{
    font-size: 14px;
    font-weight: 700;
    color: $dark;
  }
  .atbdb_content_module_contents{
    .form-group{
      margin-bottom: remy(25px);
      &:last-child{
        margin-bottom: 0;
      }
      .pricing-options{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        span{
          margin: 0 remy(15px);
        }
        .custom-checkbox{
          .custom-control-label{
            padding-left: 30px;
            font-size: remy(14px);
          }
        }
      }
      .pricing-option-inputs{
        margin-top: remy(10px);
      }
      .removeSocialField,
      .adl-move-icon{
        padding: remy(7px) remy(10px);
        border-radius: 3px;
        display: inline-block;
        margin: 0 remy(5px);
        cursor: pointer;
      }
    }
    .cor-wrap{
      margin: remy(30px) 0 remy(15px) 0;
    }
  }
  .user_info_wrap{
    .form-group{
      margin-bottom: remy(25px);
      &:last-child{
        margin-bottom: remy(20px);
      }
      #user_name{
        cursor: not-allowed;
      }
    }
  }
}

/* Add listing Business widget */
.atbd_mark_as_closed{
  margin-top: remy(10px);
}

/* Add listing FAQ styles */
#faqs_info_sortable_container{
  .removeFAQSField,
  .adl-move-icon{
    padding: remy(7px) remy(10px);
    border-radius: 3px;
    display: inline-block;
    cursor: pointer;
  }
  .removeFAQSField{
    margin-right: remy(10px);
  }
}


/* About page styles */
.about-wrapper{
  position: relative;
  padding-bottom: remy(144px);
  &:after{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background: url("img/svg/about-shape.svg");
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .about-intro{
    padding-top: remy(45px);
    h1{
      font-size: remy(40px);
      color: #fff;
      margin-bottom: remy(30px);
    }
  }
}

/* counterup styles */
.counters-wrapper{
  h1{
    font-size: remy(40px);
    color: #fff;
  }
  p{
    font-size: remy(24px);
    color: rgba(#fff, 0.8);
    margin: remy(10px) 0 0;
  }
  .counter-items{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: remy(45px);
    margin-bottom: 0;
    li{
      width: 25%;
      p{
        margin: 0 0 remy(15px) 0;
        font-size: remy(36px);
        font-weight: 700;
        color: #fff;
      }
      & > span{
        color: #fff;
        font-size: 16px;
      }
    }
  }
}

/* faq page style */
.faq-contents{
  box-shadow: 0 remy(10px) remy(10px) rgba($light-gray, 0.08);
}

/* blog page styles */
.blog-single{
  margin-bottom: remy(30px);
}
.blog-grid{
  .grid-single{
    margin-bottom: remy(30px);
  }
}

/* blog details */
.post-details{
  img{
    width: 100%;
  }
  .post-head{
    margin-bottom: remy(37px);
  }
  .post-content{
    .post-header{
      margin-bottom: remy(30px);
      h3{
        @extend .color-dark;
        margin-bottom: remy(20px);
      }
      ul{
        display: flex;
        padding-bottom: remy(30px);
        border-bottom: 1px solid $border-color;
        li{
          margin-right: remy(20px);
          &:last-child{
            margin-right: 0;
          }
          a{
            @extend .color-dark;
          }
        }
      }
    }
    .post-body{
      h4, h5{
        margin-bottom: remy(20px);
      }
    }
  }
}
.post-bottom{
  align-items: center;
  padding: remy(30px) 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  margin: remy(45px) 0 remy(70px) 0;
  flex-wrap: wrap;
  .tags{
    flex: 1;
    ul{
      margin-bottom: 0;
      li{
        margin-right: remy(10px);
        &:last-child{
          margin-right: 0;
        }
        a{
          line-height: remy(28px);
          display: block;
          border: 1px solid $border-color;
          border-radius: remy(3px);
          padding: 0 remy(12px);
          transition: $transition-base;
          color: $body-color;
          font-size: remy(14px);
          &:hover{
            @extend .color-light;
            @extend .bg-primary;
            border-color: $primary;
          }
        }
      }
    }
  }
  .social-share{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ul{
      margin: 0;
      li{
        margin-right: remy(6px);
        &:last-child{
          margin-right: 0;
        }
        a{
          width: remy(38px);
          height: remy(38px);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: remy(300px);
          background: rgba($dark, 0.05);
          color: $light-gray;
          &:hover{
            @extend .bg-primary;
            color: #fff;
          }
        }
      }
    }
  }
}

.post-author{
  padding: remy(40px);
  display: flex;
  margin-bottom: remy(60px);
  .author-thumb{
    flex: 1;
  }
  .author-info{
    flex: 4;
    h5{
      @extend .color-dark;
      margin-bottom: remy(15px);
      span{
        @extend .color-primary;
      }
    }
    .social-basic{
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      li{
        margin-right: remy(15px);
        &:last-child{
          margin-right: 0;
        }
        a{
          color: $light-gray;
        }
      }
    }
  }
}
.post-pagination{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $border-color;
  padding-bottom: remy(60px);
  .prev-post, .next-post{
    flex: 1;
    & > span{
      display: block;
    }
    .title{
      font-size: 16px;
      @extend .color-dark;
      font-weight: 600;
      margin: remy(7px) 0;
      display: block;
      transition: $transition-base;
      &:hover{
        @extend .color-primary;
      }
    }
    p{
      margin: 0;
      a{
        @extend .color-info;
      }
    }
  }
  .next-post{
    text-align: right;
  }
}
.related-post{
  border-bottom: 1px solid $border-color;
  .related-post--title{
    margin-bottom: remy(27px);
    text-align: center;
    h3{
      margin: 0;
    }
  }
  .single-post{
    margin-bottom: remy(50px);
    img{
      width: 100%;
    }
    h6{
      margin: remy(18px) 0 remy(5px);
      font-size: remy(15px);
      a{
        @extend .color-dark;
      }
    }
    p{
      font-size: remy(14px);
    }
  }
}
.comments-area{
  .comment-title{
    margin-bottom: remy(25px);
    text-align: center;
    h3{
      position: relative;
      margin-bottom: 0;
    }
  }
  .comment-lists{
    ul{
      .depth-1{
        padding: remy(30px) remy(30px) remy(24px);
        border: 1px solid $border-color;
        border-radius: remy(4px);
        margin-bottom: remy(30px);
        &:last-child{
          margin-bottom: 0;
        }
        .media{
          display: flex;
          .media-body{
            margin-left: remy(25px);
            .media_top{
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              margin-bottom: remy(15px);
              h6{
                margin-bottom: remy(7px);
              }
              .reply{
                border: 1px solid $border-color;
                color: $body-color;
                line-height: remy(34px);
                border-radius: remy(25px);
                display: inline-block;
                font-weight: 500;
                font-size: remy(13px);
                padding: 0 remy(15px);
                transition: $transition-base;
                &:hover{
                  @extend .color-light;
                  @extend .bg-primary;
                  border-color: $primary;
                }
              }
            }
          }
          p{
            margin: 0;
          }
        }
        .depth-2{
          padding-left: remy(40px);
          margin-top: remy(30px);
          padding-top: remy(30px);
          border-top: 1px solid $border-color;
        }
      }
    }
  }
}
.comment-form{
  padding: remy(45px) remy(40px);
  .comment-title{
    h3{
      @extend .color-dark;
      margin-bottom: remy(10px);
    }
  }
  form{
    textarea{
      height: remy(125px);
    }
  }
}

/* checkout page styles */
.checkout-table{
  .table{
    background: #fff;
    thead th{
      border-bottom: 0;
      padding: remy(20px) remy(30px);
    }
    tbody{
      td{
        border-color: $border-color;
        padding: remy(20px) remy(30px);
        p{
          margin: remy(5px) 0 0 0;
        }
        #atbdp_checkout_total_amount{
          font-weight: 700;
        }
        .custom-control-inline{
          margin-right: 0;
          .custom-control-label{
            min-width: auto;
          }
        }
      }
    }
  }
}
#directorist_payment_gateways{
  .atbdb_content_module_contents{
    ul{
      margin: 0;
      li{
        margin-bottom: remy(20px);
        padding-bottom: remy(20px);
        border-bottom: 1px solid $border-color;
        &:last-child{
          margin-bottom: 0;
        }
        p{
          margin: remy(10px) 0 0;
        }
        .custom-control-inline{
          align-items: center;
          .custom-control-label{
            font-weight: 700;
            color: $dark;
          }
        }
      }
    }
  }
}

/* Invoice styles */
.payment_receipt--wrapper{
  background: #fff;
  padding: remy(50px);
  .atbd_thank_you{
    text-align: center;
    margin: 0 0 remy(15px);
  }
  h4{
    margin: remy(30px) 0 remy(10px);
  }
  ul{
    margin-bottom: remy(30px);
    li{
      margin-bottom: remy(6px);
      &:last-child{
        margin-bottom: 0;
      }
      span{
        color: $dark;
        font-weight: 600;
      }
    }
  }
  .table{
    h4{
      margin-top: 0;
    }
  }
  .atbd_payment_summary_wrapper{
    margin-top: remy(50px);
    margin-bottom: remy(50px);
    .table{
      td{
        padding: remy(15px) remy(20px);
      }
    }
  }
  .atbd_payment_summary{
    font-size: remy(18px);
    color: $dark;
    font-weight: 700;
    text-align: center;
    margin: 0 0 remy(25px);
  }
}








